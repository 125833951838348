import PagePost from '../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import { unsubscribe as pageMeta } from '../data/page_meta.yml';
import { siteMeta } from '../data/site_meta.yml';

import {
    Hero,
    SEO,
    PegasusEmailOptOutForm
} from '@brandedholdings/react-components';


import PageWrapper from '../components/DefaultPageWrapper';



class Unsubscribe extends React.Component {
    getChildContext() {
        return { siteMeta };
    }

    render() {
        const page = this.props;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);


        return (
            <PageWrapper>
                <SEO post={ post } />
                <Hero
                    heading="Unsubscribe"
                    subheading=""
                    actionText={null}
                    actionLink="#"
                    heroModifiers="hero--empty"
                    actionModifiers={null}
                />
                <div className="layout">
                    <PegasusEmailOptOutForm siteMeta={ siteMeta } />
                </div>
            </PageWrapper>
        );
    }
};

Unsubscribe.childContextTypes = {
    siteMeta: PropTypes.object
};

export default Unsubscribe;
